import { all, takeLatest } from 'redux-saga/effects';

import * as actionLabels from '../actionLabels';
import {
  forgetPasswordSaga,
  loginSaga,
  resendOtpSaga,
  resetPasswordSaga,
  verifyForgetPasswordOtpSaga,
  verifyOtpSaga,
} from './auth/auth';
import {
  getDashboardSalesSaga,
  getDashboardStatsSaga,
  getRecentDeviceSaga,
  getTopArtistSaga,
  getHotNftSaga,
  getTopCollectionsSaga,
} from './dashboard/dashboard';
import { getRewardListSaga } from './reward/reward';

import { getDeviceDetailsSaga, getDeviceListSaga } from './device/device';
import { changePasswordSaga } from './password/password';
import {
  getCollectionDetailsSaga,
  getCollectionItemListSaga,
  getNftItemDetailsSaga,
  getUserReportCollectionItemListSaga,
  getUserReportCollectionListSaga,
  getUserReportDetailsSaga,
  getUserReportListSaga,
  hideCollectionSaga,
  hideNftDetailsSaga,
} from './userReportManagement/userReportManagement';

import {
  disabeUserSaga,
  getUserDetailsSaga,
  getUserDeviceListSaga,
  getUserListSaga,
  getUserTransactionListSaga,
  suspendUserSaga,
} from './user/user';

import {
  createCategorySaga,
  deleteCategorySaga,
  getCategoryDetailsSaga,
  getCategoryListSaga,
  updateCategorySaga,
} from './category/category';
import { getProfileDetailsSaga, updatePasswordSaga } from './profile/profile';
import {
  getTransactionListSaga,
  getNftTransactionsListSaga,
  getFundTransactionsListSaga,
} from './transaction/transaction';
import { getCmsContentSaga, pushCmsContentSaga } from './cms/cms';
import {
  createKycReminderSaga,
  deleteKycReminderSaga,
  deletePushNotificationSaga,
  getKycReminderListSaga,
  getKycReminderSaga,
  getLocationsSaga,
  getNotificationHistorySaga,
  getNotificationListSaga,
  readClearAllNotificationSaga,
  readClearNotificationSaga,
  resendPushNotificationSaga,
  sendEmailSaga,
  sendPushNotificationSaga,
  updateKycReminderSaga,
} from './notification/notification';
import {
  createAdminSaga,
  deleteAdminSaga,
  disableEnableAdminSaga,
  getAdminListSaga,
  getAdminSaga,
  getAllAdminSaga,
  updateAdminSaga,
} from './adminManagement/adminManagement';
import {
  createRoleSaga,
  deleteRoleSaga,
  getAllRoleSaga,
  getPermissionsSaga,
  getRoleListSaga,
  getRoleSaga,
  updateRoleSaga,
} from './roleManagement/roleManagement';
import {
  getSubscriptionDetailsSaga,
  getSubscriptionListSaga,
  updateSubscriptionSaga,
  updateSubscriptionTxnFeeSaga,
  getSubscriptionTxnFeeSaga,
} from './subscription/subscription';
import {
  approveDropsSaga,
  getDropsDetailsSaga,
  getDropsRequestsSaga,
  getLiveDropsSaga,
  rejectDropsSaga,
} from './dropManagement/dropManagement';
import {
  getTradingDashboardBalancesSaga,
  getTradingDashboardTopAssetsSaga,
  getTradingDashboardTopOrdersSaga,
  getTradingDashboardTopPairsSaga,
  getTradingDashboardTxnGraphSaga,
} from './trading-dashboard/trading-dashboard';

export function* watchAuthentication() {
  yield all([
    takeLatest(actionLabels.LOGIN_SAGA, loginSaga),
    takeLatest(actionLabels.VERIFY_OTP_SAGA, verifyOtpSaga),
    takeLatest(actionLabels.FORGET_PASSWORD_SAGA, forgetPasswordSaga),
    takeLatest(actionLabels.VERIFY_FORGET_PASSWORD_OTP_SAGA, verifyForgetPasswordOtpSaga),
    takeLatest(actionLabels.RESET_PASSWORD_SAGA, resetPasswordSaga),
    takeLatest(actionLabels.RESEND_OTP_SAGA, resendOtpSaga),
  ]);
}
export function* watchDashboard() {
  yield all([
    takeLatest(actionLabels.GET_DASHBOARD_STATS_SAGA, getDashboardStatsSaga),
    takeLatest(actionLabels.GET_RECENT_DEVICE_SAGA, getRecentDeviceSaga),
    takeLatest(actionLabels.GET_DASHBOARD_SALES_SAGA, getDashboardSalesSaga),
    takeLatest(actionLabels.GET_TOP_ARTISTS_SAGA, getTopArtistSaga),
    takeLatest(actionLabels.GET_HOT_NFT_SAGA, getHotNftSaga),
    takeLatest(actionLabels.GET_TOP_COLLECTIONS_SAGA, getTopCollectionsSaga),
  ]);
}

export function* watchUser() {
  yield all([
    takeLatest(actionLabels.GET_USER_LIST_SAGA, getUserListSaga),
    takeLatest(actionLabels.GET_USER_DETAILS_SAGA, getUserDetailsSaga),
    takeLatest(actionLabels.DISABLE_USER_SAGA, disabeUserSaga),
    takeLatest(actionLabels.GET_USER_DEVICE_LIST_SAGA, getUserDeviceListSaga),
    takeLatest(actionLabels.SUSPEND_USER_SAGA, suspendUserSaga),
    takeLatest(actionLabels.GET_USER_COLLECTION_LIST_SAGA, getUserReportCollectionListSaga),
    takeLatest(actionLabels.GET_USER_TRANSACTION_LIST_SAGA, getUserTransactionListSaga),
  ]);
}

export function* watchReward() {
  yield all([takeLatest(actionLabels.GET_REWARD_LIST_SAGA, getRewardListSaga)]);
}

export function* watchDevice() {
  yield all([
    takeLatest(actionLabels.GET_DEVICE_LIST_SAGA, getDeviceListSaga),
    takeLatest(actionLabels.GET_DEVICE_DETAILS_SAGA, getDeviceDetailsSaga),
  ]);
}

export function* watchPassword() {
  yield all([takeLatest(actionLabels.CHANGE_PASSWORD_SAGA, changePasswordSaga)]);
}

export function* watchUserReport() {
  yield all([takeLatest(actionLabels.GET_USER_REPORT_LIST_SAGA, getUserReportListSaga)]);
  yield all([takeLatest(actionLabels.GET_USER_REPORT_DETAILS_SAGA, getUserReportDetailsSaga)]);
  yield all([takeLatest(actionLabels.GET_COLLECTION_DETAILS_SAGA, getCollectionDetailsSaga)]);
  yield all([takeLatest(actionLabels.HIDE_COLLECTION_SAGA, hideCollectionSaga)]);
  yield all([takeLatest(actionLabels.GET_COLLECTION_ITEM_LIST_SAGA, getCollectionItemListSaga)]);
  yield all([
    takeLatest(
      actionLabels.GET_USER_REPORT_COLLECTION_ITEM_LIST_SAGA,
      getUserReportCollectionItemListSaga,
    ),
  ]);
  yield all([takeLatest(actionLabels.GET_NFT_ITEM_DETAILS_SAGA, getNftItemDetailsSaga)]);
  yield all([takeLatest(actionLabels.HIDE_NFT_DETAILS_SAGA, hideNftDetailsSaga)]);
}

export function* watchCategory() {
  yield all([
    takeLatest(actionLabels.GET_CATEGORY_LIST_SAGA, getCategoryListSaga),
    takeLatest(actionLabels.GET_CATEGORY_DETAILS_SAGA, getCategoryDetailsSaga),
    takeLatest(actionLabels.CREATE_CATEGORY_SAGA, createCategorySaga),
    takeLatest(actionLabels.UPDATE_CATEGORY_SAGA, updateCategorySaga),
    takeLatest(actionLabels.DELETE_CATEGORY_SAGA, deleteCategorySaga),
  ]);
}

export function* watchSubscription() {
  yield all([
    takeLatest(actionLabels.GET_SUBSCRIPTION_LIST_SAGA, getSubscriptionListSaga),
    takeLatest(actionLabels.GET_SUBSCRIPTION_DETAILS_SAGA, getSubscriptionDetailsSaga),
    takeLatest(actionLabels.UPDATE_SUBSCRIPTION_SAGA, updateSubscriptionSaga),
    takeLatest(actionLabels.UPDATE_SUBSCRIPTION_TXN_FEE_SAGA, updateSubscriptionTxnFeeSaga),
    takeLatest(actionLabels.GET_SUBSCRIPTION_TXN_FEE_SAGA, getSubscriptionTxnFeeSaga),
  ]);
}

export function* watchProfile() {
  yield all([takeLatest(actionLabels.GET_PROFILE_DETAILS_SAGA, getProfileDetailsSaga)]);
  yield all([takeLatest(actionLabels.UPDATE_PASSWORD_SAGA, updatePasswordSaga)]);
}

export function* watchTransaction() {
  yield all([takeLatest(actionLabels.GET_TRANSACTION_LIST_SAGA, getTransactionListSaga)]);
  yield all([takeLatest(actionLabels.GET_NFT_TRANSACTIONS_LIST_SAGA, getNftTransactionsListSaga)]);
  yield all([
    takeLatest(actionLabels.GET_FUND_TRANSACTIONS_LIST_SAGA, getFundTransactionsListSaga),
  ]);
}

export function* watchCms() {
  yield all([
    takeLatest(actionLabels.GET_CMS_CONTENT_SAGA, getCmsContentSaga),
    takeLatest(actionLabels.PUSH_CMS_CONTENT_SAGA, pushCmsContentSaga),
  ]);
}

export function* watchNotification() {
  yield all([
    takeLatest(actionLabels.GET_NOTIFICATION_LIST_SAGA, getNotificationListSaga),
    takeLatest(actionLabels.READ_CLEAR_NOTIFICATION_SAGA, readClearNotificationSaga),
    takeLatest(actionLabels.READ_CLEAR_ALL_NOTIFICATION_SAGA, readClearAllNotificationSaga),
    takeLatest(actionLabels.GET_NOTIFICATION_HISTORY_SAGA, getNotificationHistorySaga),
    takeLatest(actionLabels.SEND_PUSH_NOTIFICATION_SAGA, sendPushNotificationSaga),
    takeLatest(actionLabels.RESEND_PUSH_NOTIFICATION_SAGA, resendPushNotificationSaga),
    takeLatest(actionLabels.DELETE_PUSH_NOTIFICATION_SAGA, deletePushNotificationSaga),
    takeLatest(actionLabels.CREATE_REMINDER_SAGA, createKycReminderSaga),
    takeLatest(actionLabels.GET_REMINDER_LIST_SAGA, getKycReminderListSaga),
    takeLatest(actionLabels.GET_REMINDER_SAGA, getKycReminderSaga),
    takeLatest(actionLabels.UPDATE_REMINDER_SAGA, updateKycReminderSaga),
    takeLatest(actionLabels.DELETE_REMINDER_SAGA, deleteKycReminderSaga),
    takeLatest(actionLabels.SEND_EMAIL_SAGA, sendEmailSaga),
    takeLatest(actionLabels.GET_LOCATIONS_SAGA, getLocationsSaga),
  ]);
}

export function* watchAdmin() {
  yield all([
    takeLatest(actionLabels.GET_ADMIN_LIST_SAGA, getAdminListSaga),
    takeLatest(actionLabels.GET_ALL_ADMIN_SAGA, getAllAdminSaga),
    takeLatest(actionLabels.GET_ADMIN_SAGA, getAdminSaga),
    takeLatest(actionLabels.CREATE_ADMIN_SAGA, createAdminSaga),
    takeLatest(actionLabels.UPDATE_ADMIN_SAGA, updateAdminSaga),
    takeLatest(actionLabels.DELETE_ADMIN_SAGA, deleteAdminSaga),
    takeLatest(actionLabels.DISABLE_ENABLE_ADMIN_SAGA, disableEnableAdminSaga),
  ]);
}

export function* watchRole() {
  yield all([
    takeLatest(actionLabels.GET_ROLE_LIST_SAGA, getRoleListSaga),
    takeLatest(actionLabels.GET_ALL_ROLE_SAGA, getAllRoleSaga),
    takeLatest(actionLabels.GET_ROLE_SAGA, getRoleSaga),
    takeLatest(actionLabels.CREATE_ROLE_SAGA, createRoleSaga),
    takeLatest(actionLabels.UPDATE_ROLE_SAGA, updateRoleSaga),
    takeLatest(actionLabels.DELETE_ROLE_SAGA, deleteRoleSaga),
    takeLatest(actionLabels.GET_PERMISSIONS_SAGA, getPermissionsSaga),
  ]);
}

export function* watchDrop() {
  yield all([takeLatest(actionLabels.GET_DROPS_REQUESTS_SAGA, getDropsRequestsSaga)]);
  yield all([takeLatest(actionLabels.GET_LIVE_DROPS_SAGA, getLiveDropsSaga)]);
  yield all([takeLatest(actionLabels.GET_DROPS_DETAILS_SAGA, getDropsDetailsSaga)]);
  yield all([takeLatest(actionLabels.APPROVE_DROPS_SAGA, approveDropsSaga)]);
  yield all([takeLatest(actionLabels.REJECT_DROPS_SAGA, rejectDropsSaga)]);
}

export function* watchTradingDashboard() {
  yield all([
    takeLatest(actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_SAGA, getTradingDashboardTopPairsSaga),
    takeLatest(actionLabels.GET_TRADING_DASHBOARD_BALANCES_SAGA, getTradingDashboardBalancesSaga),
    takeLatest(
      actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_SAGA,
      getTradingDashboardTopAssetsSaga,
    ),
    takeLatest(
      actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_SAGA,
      getTradingDashboardTopOrdersSaga,
    ),
    takeLatest(actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_SAGA, getTradingDashboardTxnGraphSaga),
  ]);
}

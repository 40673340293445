export const GET_TRADING_DASHBOARD_TOP_PAIRS_SAGA = 'GET_TRADING_DASHBOARD_TOP_PAIRS_SAGA';
export const GET_TRADING_DASHBOARD_TOP_PAIRS_START = 'GET_TRADING_DASHBOARD_TOP_PAIRS_START';
export const GET_TRADING_DASHBOARD_TOP_PAIRS_SUCCESS = 'GET_TRADING_DASHBOARD_TOP_PAIRS_SUCCESS';
export const GET_TRADING_DASHBOARD_TOP_PAIRS_FAIL = 'GET_TRADING_DASHBOARD_TOP_PAIRS_FAIL';


export const GET_TRADING_DASHBOARD_BALANCES_SAGA = 'GET_TRADING_DASHBOARD_BALANCES_SAGA';
export const GET_TRADING_DASHBOARD_BALANCES_START = 'GET_TRADING_DASHBOARD_BALANCES_START';
export const GET_TRADING_DASHBOARD_BALANCES_SUCCESS = 'GET_TRADING_DASHBOARD_BALANCES_SUCCESS';
export const GET_TRADING_DASHBOARD_BALANCES_FAIL = 'GET_TRADING_DASHBOARD_BALANCES_FAIL';


export const GET_TRADING_DASHBOARD_TOP_ASSETS_SAGA = 'GET_TRADING_DASHBOARD_TOP_ASSETS_SAGA';
export const GET_TRADING_DASHBOARD_TOP_ASSETS_START = 'GET_TRADING_DASHBOARD_TOP_ASSETS_START';
export const GET_TRADING_DASHBOARD_TOP_ASSETS_SUCCESS = 'GET_TRADING_DASHBOARD_TOP_ASSETS_SUCCESS';
export const GET_TRADING_DASHBOARD_TOP_ASSETS_FAIL = 'GET_TRADING_DASHBOARD_TOP_ASSETS_FAIL';


export const GET_TRADING_DASHBOARD_TOP_ORDERS_SAGA = 'GET_TRADING_DASHBOARD_TOP_ORDERS_SAGA';
export const GET_TRADING_DASHBOARD_TOP_ORDERS_START = 'GET_TRADING_DASHBOARD_TOP_ORDERS_START';
export const GET_TRADING_DASHBOARD_TOP_ORDERS_SUCCESS = 'GET_TRADING_DASHBOARD_TOP_ORDERS_SUCCESS';
export const GET_TRADING_DASHBOARD_TOP_ORDERS_FAIL = 'GET_TRADING_DASHBOARD_TOP_ORDERS_FAIL';


export const GET_TRADING_DASHBOARD_TXN_GRAPH_SAGA = 'GET_TRADING_DASHBOARD_TXN_GRAPH_SAGA';
export const GET_TRADING_DASHBOARD_TXN_GRAPH_START = 'GET_TRADING_DASHBOARD_TXN_GRAPH_START';
export const GET_TRADING_DASHBOARD_TXN_GRAPH_SUCCESS = 'GET_TRADING_DASHBOARD_TXN_GRAPH_SUCCESS';
export const GET_TRADING_DASHBOARD_TXN_GRAPH_FAIL = 'GET_TRADING_DASHBOARD_TXN_GRAPH_FAIL';


export const CLEAR_AUTH = 'CLEAR_AUTH';

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from 'store/reducer';

import {
  watchAuthentication,
  watchDashboard,
  watchDevice,
  watchReward,
  watchPassword,
  watchUser,
  watchUserReport,
  watchCategory,
  watchProfile,
  watchTransaction,
  watchCms,
  watchNotification,
  watchRole,
  watchAdmin,
  watchSubscription,
  watchDrop,
  watchTradingDashboard,
} from './sagas';

const composeEnhancers =
  // eslint-disable-next-line no-underscore-dangle
  (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) ||
  compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  //   { auth: { ...loadState() } },
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(watchAuthentication);
sagaMiddleware.run(watchDashboard);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchReward);
sagaMiddleware.run(watchDevice);
sagaMiddleware.run(watchPassword);
sagaMiddleware.run(watchUserReport);
sagaMiddleware.run(watchCategory);
sagaMiddleware.run(watchProfile);
sagaMiddleware.run(watchTransaction);
sagaMiddleware.run(watchCms);
sagaMiddleware.run(watchNotification);
sagaMiddleware.run(watchRole);
sagaMiddleware.run(watchAdmin);
sagaMiddleware.run(watchSubscription);
sagaMiddleware.run(watchDrop);
sagaMiddleware.run(watchTradingDashboard);

export default store;

/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // get subscription list
  getSubscriptionListLoading: false,
  getSubscriptionListErrorMsg: '',
  subscriptionList: [],
  // get subscription details
  getSubscriptionDetailsLoading: false,
  getSubscriptionDetailsErrorMsg: '',
  subscriptionDetails: null,
  // update subscription
  updateSubscriptionLoading: false,
  updateSubscriptionErrorMsg: '',

  // update subscription txn fee
  updateSubscriptionTxnFeeLoading: false,
  updateSubscriptionTxnFeeErrorMsg: '',
  subscriptionTxnFeeDetails: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // get subscription list
    case actionLabels.GET_SUBSCRIPTION_LIST_START:
      return { ...state, getSubscriptionListLoading: true, getSubscriptionListErrorMsg: '' };
    case actionLabels.GET_SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        getSubscriptionListLoading: false,
        getSubscriptionListErrorMsg: '',
        subscriptionList: payload,
      };
    case actionLabels.GET_SUBSCRIPTION_LIST_FAIL:
      return {
        ...state,
        getSubscriptionListLoading: false,
        getSubscriptionListErrorMsg: payload,
        subscriptionList: [],
      };

    // get subscription details
    case actionLabels.GET_SUBSCRIPTION_DETAILS_START:
      return { ...state, getSubscriptionDetailsLoading: true, getSubscriptionDetailsErrorMsg: '' };
    case actionLabels.GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        getSubscriptionDetailsLoading: false,
        getSubscriptionDetailsErrorMsg: '',
        subscriptionDetails: payload,
      };
    case actionLabels.GET_SUBSCRIPTION_DETAILS_FAIL:
      return {
        ...state,
        getSubscriptionDetailsLoading: false,
        getSubscriptionDetailsErrorMsg: payload,
      };

    // update subscription
    case actionLabels.UPDATE_SUBSCRIPTION_START:
      return { ...state, updateSubscriptionLoading: true, updateSubscriptionErrorMsg: '' };
    case actionLabels.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updateSubscriptionLoading: false,
        updateSubscriptionErrorMsg: '',
      };
    case actionLabels.UPDATE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        updateSubscriptionLoading: false,
        updateSubscriptionErrorMsg: payload,
      };

    
    case actionLabels.UPDATE_SUBSCRIPTION_TXN_FEE_START:
      return { ...state, updateSubscriptionTxnFeeLoading: true, updateSubscriptionTxnFeeErrorMsg: '' };
    case actionLabels.UPDATE_SUBSCRIPTION_TXN_FEE_SUCCESS:
      return {
        ...state,
        updateSubscriptionTxnFeeLoading: false,
        updateSubscriptionTxnFeeErrorMsg: '',
      };
    case actionLabels.UPDATE_SUBSCRIPTION_TXN_FEE_FAIL:
      return {
        ...state,
        updateSubscriptionTxnFeeLoading: false,
        updateSubscriptionTxnFeeErrorMsg: payload,
      };

      case actionLabels.GET_SUBSCRIPTION_TXN_FEE_START:
        return { ...state, getSubscriptionTxnFeeLoading: true, getSubscriptionTxnFeeErrorMsg: '' };
      case actionLabels.GET_SUBSCRIPTION_TXN_FEE_SUCCESS:
        return {
          ...state,
          getSubscriptionTxnFeeLoading: false,
          getSubscriptionTxnFeeErrorMsg: '',
          subscriptionTxnFeeDetails: payload,
        };
      case actionLabels.GET_SUBSCRIPTION_TXN_FEE_FAIL:
        return {
          ...state,
          getSubscriptionTxnFeeLoading: false,
          getSubscriptionTxnFeeErrorMsg: payload,
        };



    default:
      return state;
  }
};

/* eslint-disable import/no-anonymous-default-export */
import * as actionLabels from '../../actionLabels';

const initialState = {
  // TOP PAIRS
  topPairsLoading: false,
  topPairsErrorMsg: '',
  topPairs: [],

  // BALANCES
  balancesLoading: false,
  balancesErrorMsg: '',
  balances: {
    BTC: '0',
    ETH: '0',
    SOL: '0',
    TRX: '0',
    DOG: '0',
    BNB: '0',
    ADA: '0',
    XRP: '0',
    USC: '0',
  },

  // TOP ASSETS
  topAssetsLoading: false,
  topAssetsErrorMsg: '',
  topAssets: [],

  // TOP ORDERS
  topOrdersLoading: false,
  topOrdersErrorMsg: '',
  topOrders: [],

  // TXN GRAPH
  txnGraphLoading: false,
  txnGraphErrorMsg: '',
  txnGraph: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // TOP PAIRS
    case actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_START:
      return { ...state, topPairsLoading: true, topPairsErrorMsg: '' };
    case actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_SUCCESS:
      return {
        ...state,
        topPairsLoading: false,
        topPairsErrorMsg: '',
        topPairs: payload,
      };

    case actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_FAIL:
      return {
        ...state,
        topPairsLoading: false,
        topPairsErrorMsg: payload,
        topPairs: [],
      };

    // BALANCES
    case actionLabels.GET_TRADING_DASHBOARD_BALANCES_START:
      return { ...state, balancesLoading: true, balancesErrorMsg: '' };
    case actionLabels.GET_TRADING_DASHBOARD_BALANCES_SUCCESS:
      return {
        ...state,
        balancesLoading: false,
        balancesErrorMsg: '',
        balances: payload.balances,
      };
    case actionLabels.GET_TRADING_DASHBOARD_BALANCES_FAIL:
      return {
        ...state,
        balancesLoading: false,
        balancesErrorMsg: payload,
        balances: null,
      };

    // TOP ASSETS
    case actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_START:
      return { ...state, topAssetsLoading: true, topAssetsErrorMsg: '' };
    case actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_SUCCESS:
      return {
        ...state,
        topAssetsLoading: false,
        topAssetsErrorMsg: '',
        topAssets: payload,
      };
    case actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_FAIL:
      return {
        ...state,
        topAssetsLoading: false,
        topAssetsErrorMsg: payload,
        topAssets: [],
      };

    // TOP ORDERS
    case actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_START:
      return { ...state, topOrdersLoading: true, topOrdersErrorMsg: '' };
    case actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_SUCCESS:
      return {
        ...state,
        topOrdersLoading: false,
        topOrdersErrorMsg: '',
        topOrders: payload,
      };
    case actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_FAIL:
      return {
        ...state,
        topOrdersLoading: false,
        topOrdersErrorMsg: payload,
        topOrders: [],
      };

    // TXN GRAPH
    case actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_START:
      return { ...state, txnGraphLoading: true, txnGraphErrorMsg: '' };
    case actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_SUCCESS:
      console.log('payload', payload);
      return {
        ...state,
        txnGraphLoading: false,
        txnGraphErrorMsg: '',
        txnGraph: payload,
      };
    case actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_FAIL:
      return {
        ...state,
        txnGraphLoading: false,
        txnGraphErrorMsg: payload,
        txnGraph: [],
      };

    default:
      return state;
  }
};

import * as actionLabels from '../../actionLabels';

// TOP PAIRS
export const getDashboardTopPairsAction = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_SAGA,
  payload,
});

export const getDashboardTopPairsStart = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_START,
  payload,
});

export const getDashboardTopPairsSuccess = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_SUCCESS,
  payload,
});

export const getDashboardTopPairsFail = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_TOP_PAIRS_FAIL,
  payload,
});


// BALANCES
export const getDashboardBalancesAction = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_BALANCES_SAGA,
  payload,
});
export const getDashboardBalancesStart = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_BALANCES_START,
  payload,
});
export const getDashboardBalancesSuccess = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_BALANCES_SUCCESS,
  payload,
});
export const getDashboardBalancesFail = payload => ({
  type: actionLabels.GET_TRADING_DASHBOARD_BALANCES_FAIL,
  payload,
});

// TOP ASSETS
export const getDashboardTopAssetsAction = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_SAGA,
    payload,
})
export const getDashboardTopAssetsStart = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_START,
    payload,
})
export const getDashboardTopAssetsSuccess = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_SUCCESS,
    payload,
})  
export const getDashboardTopAssetsFail = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ASSETS_FAIL,
    payload,
})

// TOP ORDERS
export const getDashboardTopOrdersAction = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_SAGA,
    payload,
})
export const getDashboardTopOrdersStart = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_START,
    payload,
})  
export const getDashboardTopOrdersSuccess = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_SUCCESS,
    payload,
})
export const getDashboardTopOrdersFail = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TOP_ORDERS_FAIL,
    payload,
})  

// TXN GRAPH
export const getDashboardTxnGraphAction = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_SAGA,
    payload,
})
export const getDashboardTxnGraphStart = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_START,
    payload,
})  
export const getDashboardTxnGraphSuccess = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_SUCCESS,
    payload,
})
export const getDashboardTxnGraphFail = payload => ({
    type: actionLabels.GET_TRADING_DASHBOARD_TXN_GRAPH_FAIL,
    payload,
})  

export const GET_SUBSCRIPTION_LIST_SAGA = 'GET_SUBSCRIPTION_LIST_SAGA';
export const GET_SUBSCRIPTION_LIST_START = 'GET_SUBSCRIPTION_LIST_START';
export const GET_SUBSCRIPTION_LIST_SUCCESS = 'GET_SUBSCRIPTION_LIST_SUCCESS';
export const GET_SUBSCRIPTION_LIST_FAIL = 'GET_SUBSCRIPTION_LIST_FAIL';

export const GET_SUBSCRIPTION_DETAILS_SAGA = 'GET_SUBSCRIPTION_DETAILS_SAGA';
export const GET_SUBSCRIPTION_DETAILS_START = 'GET_SUBSCRIPTION_DETAILS_START';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_SUBSCRIPTION_DETAILS_SUCCESS';
export const GET_SUBSCRIPTION_DETAILS_FAIL = 'GET_SUBSCRIPTION_DETAILS_FAIL';

export const UPDATE_SUBSCRIPTION_SAGA = 'UPDATE_SUBSCRIPTION_SAGA';
export const UPDATE_SUBSCRIPTION_START = 'UPDATE_SUBSCRIPTION_START';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAIL = 'UPDATE_SUBSCRIPTION_FAIL';

export const UPDATE_SUBSCRIPTION_TXN_FEE_SAGA = 'UPDATE_SUBSCRIPTION_TXN_FEE_SAGA';
export const UPDATE_SUBSCRIPTION_TXN_FEE_START = 'UPDATE_SUBSCRIPTION_TXN_FEE_START';
export const UPDATE_SUBSCRIPTION_TXN_FEE_SUCCESS = 'UPDATE_SUBSCRIPTION_TXN_FEE_SUCCESS';
export const UPDATE_SUBSCRIPTION_TXN_FEE_FAIL = 'UPDATE_SUBSCRIPTION_TXN_FEE_FAIL';

export const GET_SUBSCRIPTION_TXN_FEE_SAGA = 'GET_SUBSCRIPTION_TXN_FEE_SAGA';
export const GET_SUBSCRIPTION_TXN_FEE_START = 'GET_SUBSCRIPTION_TXN_FEE_START';
export const GET_SUBSCRIPTION_TXN_FEE_SUCCESS = 'GET_SUBSCRIPTION_TXN_FEE_SUCCESS';
export const GET_SUBSCRIPTION_TXN_FEE_FAIL = 'GET_SUBSCRIPTION_TXN_FEE_FAIL';

import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';

import {
  getDashboardTopPairsStart,
  getDashboardTopPairsSuccess,
  getDashboardTopPairsFail,
  getDashboardBalancesStart,
  getDashboardBalancesSuccess,
  getDashboardBalancesFail,
  getDashboardTopAssetsStart,
  getDashboardTopAssetsSuccess,
  getDashboardTopAssetsFail,
  getDashboardTxnGraphStart,
  getDashboardTxnGraphSuccess,
  getDashboardTxnGraphFail,
  getDashboardTopOrdersStart,
  getDashboardTopOrdersSuccess,
  getDashboardTopOrdersFail,
} from 'store/actions';

import {
  GET_TRADING_DASHBOARD_TOP_PAIRS_URL,
  GET_TRADING_DASHBOARD_TOP_ORDERS_URL,
  GET_TRADING_DASHBOARD_BALANCES_URL,
  GET_TRADING_DASHBOARD_TXN_GRAPH_URL,
  GET_TRADING_DASHBOARD_TOP_ASSETS_URL,
} from 'apis';

// get top pairs saga
export function* getTradingDashboardTopPairsSaga() {
  yield put(getDashboardTopPairsStart());
  yield errorHandler({
    endpoint: GET_TRADING_DASHBOARD_TOP_PAIRS_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardTopPairsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardTopPairsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get balances saga
export function* getTradingDashboardBalancesSaga() {
  yield put(getDashboardBalancesStart());
  yield errorHandler({
    endpoint: GET_TRADING_DASHBOARD_BALANCES_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardBalancesSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardBalancesFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get top assets saga
export function* getTradingDashboardTopAssetsSaga() {
  yield put(getDashboardTopAssetsStart());
  yield errorHandler({
    endpoint: GET_TRADING_DASHBOARD_TOP_ASSETS_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardTopAssetsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardTopAssetsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get txn graph saga
export function* getTradingDashboardTxnGraphSaga({ payload }) {
  const { filterBy, currency, side } = payload;
  yield put(getDashboardTxnGraphStart());
  yield errorHandler({
    endpoint: `${GET_TRADING_DASHBOARD_TXN_GRAPH_URL}?filterBy=${filterBy}&currency=${currency}&side=${side}`,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardTxnGraphSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardTxnGraphFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

// get top orders saga
export function* getTradingDashboardTopOrdersSaga() {
  yield put(getDashboardTopOrdersStart());
  yield errorHandler({
    endpoint: GET_TRADING_DASHBOARD_TOP_ORDERS_URL,
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardTopOrdersSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardTopOrdersFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}

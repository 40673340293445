// auth ----------------------------------------------------
export const LOGIN_URL = '/admin/auth/login';

export const VERIFY_OTP_URL = '/admin/auth/verifyLogin';

export const FORGOT_PASSWORD_URL = '/admin/auth/forgotPassword';

export const RESET_PASSWORD_URL = '/admin/auth/resetPassword';

export const VERIFY_FORGOT_PASSWORD_OTP = '/admin/auth/verifyResetRequest';

export const RESEND_OTP_URL = '/admin/auth/resend-otp';

// admin ---------------------------------------------------

// dashboard
export const GET_DASHBOARD_STATS_URL = '/admin/dashboard/stats';
export const GET_RECENT_DEVICE_URL = '/device/recentDevice';
export const GET_DASHBOARD_SALES_URL = '/admin/dashboard/sales';
export const GET_TOP_ARTISTS_URL = '/dashboard/top-artists';
export const GET_HOT_NFT_URL = '/dashboard/hot-items';
export const GET_TOP_COLLECTIONS_URL = '/dashboard/top-collections';

// User Management

export const DISABLE_USER_URL = '';
export const GET_USER_DEVICE_LIST_URL = '';
export const GET_USER_LIST_URL = '/dummy/user/all';
export const SUSPEND_USER_URL = '/dummy/user/status';
export const GET_USER_COLLECTION_LIST_URL = '';
export const GET_USER_DETAILS_URL = '/dummy/user/get';

//Reported Collection/NFT
export const GET_USER_REPORT_LIST_URL = '/dummy/report/all';
export const GET_USER_REPORT_DETAILS_URL = '/dummy/report/collection-item';
export const GET_COLLECTION_DETAILS_URL = '/dummy/collection/get';
export const GET_USER_REPORT_COLLECTION_LIST_URL = '/dummy/collection/user-collection';
export const HIDE_COLLECTION_URL = '/dummy/collection/hide-unhide';
export const GET_COLLECTION_ITEM_LIST_URL = '/dummy/item/user-items';
export const GET_USER_REPORT_COLLECTION_ITEM_LIST_URL = 'dummy/item/collectionItems';
export const GET_USER_TRANSACTION_LIST_URL = '/dummy/transaction/user-all';
export const GET_NFT_ITEM_DETAILS_URL = '/dummy/item/get';
export const HIDE_NFT_DETAILS_URL = '/dummy/item/hide-unhide';

// Device Management
export const GET_DEVICE_LIST_URL = '/device/getAllDevice';
export const GET_DEVICE_DETAILS_URL = '/device/deviceDetails';

// Reward Management
export const GET_REWARD_LIST_URL = '/reward/all-rewards';

// Change Password
export const CHANGE_PASSWORD_URL = '/admin/updatePassword';

// Category Management
export const GET_CATEGORY_LIST_URL = '/category/get-all';
export const GET_CATEGORY_DETAILS_URL = '/category/get';
export const CREATE_CATEGORY_URL = '/category/create';
export const UPDATE_CATEGORY_URL = '/category/update';
export const DELETE_CATEGORY_URL = '/category/delete';

// profile details
export const GET_PROFILE_DETAILS_URL = '/admin/profile/view';
export const UPDATE_PASSWORD_URL = '/admin/profile/update';

// Transaction Management
export const TRANSACTION_LIST_URL = '/dummy/transaction/get-all';
export const TRANSACTION_NFT_URL = '/dummy/transaction/nft-all';
export const TRANSACTION_FUNDS_URL = '/dummy/transaction/funds-all';

// cms
export const GET_CMS_URL = '/cms/get';
export const UPDATE_CMS_URL = '/cms/update';

// notification
export const GET_NOTIFICATION_LIST_URL = '/dummy/notification/all';
export const READ_CLEAR_NOTIFICATION_URL = '/dummy/notification/update';
export const READ_CLEAR_ALL_NOTIFICATION_URL = '/dummy/notification/update-all';

// notification management
export const GET_NOTIFICATION_MANAGEMENT_HISTORY_URL = '/notification';
export const SEND_PUSH_NOTIFICATION_URL = '/notification/send';
export const RESEND_PUSH_NOTIFICATION_URL = '/notification/resend';
export const DELETE_PUSH_NOTIFICATION_URL = '/notification/remove';

export const GET_KYC_REMINDER_LIST_URL = '/notification/kyc-reminder/list';
export const GET_KYC_REMINDER_URL = '/notification/kyc-reminder';
export const CREATE_KYC_REMINDER_URL = '/notification/kyc-reminder';
export const UPDATE_KYC_REMINDER_URL = '/notification/kyc-reminder';
export const DELETE_KYC_REMINDER_URL = '/notification/kyc-reminder';

export const SEND_EMAIL_URL = '/notification/bulkEmail';
export const UPLOAD_ATTACHMENT_URL = '/notification/upload-attachment';

export const GET_LOCATIONS_URL = '/country/list';

// admin management
export const GET_ALL_ADMIN_URL = '/admin/list';
export const GET_ADMIN_URL = '/admin';
export const ADMIN_LIST_URL = '/admin/adminName-list';
export const ADMIN_STATUS_URL = '/admin/change-status';

// role management
export const GET_ALL_ROLE_URL = '/admin/role/list';
export const GET_ROLE_URL = '/admin/role';
export const ROLE_LIST_URL = '/admin/role/roleName-list';
export const GET_PERMISSIONS_URL = '/admin/role/permissions';

// Subscription management
export const GET_SUBSCRIPTION_LIST_URL = '/subscription/get-all';
export const GET_SUBSCRIPTION_DETAILS_URL = '/subscription/get';
export const UPDATE_SUBSCRIPTION_URL = '/subscription/update';
export const UPDATE_SUBSCRIPTION_TXN_FEE_URL = '/subscription/txn-fee';

// drops managment
export const GET_DROPS_REQUESTS_URL = '/drop?status=Pending';
export const GET_LIVE_DROPS_URL = '/drop?status=Active';
export const GET_DROPS_DETAILS_URL = '/drop/details';
export const APPROVE_DROPS_URL = '/drop/approve';
export const REJECT_DROPS_URL = '/drop/reject';

// Trading Dashboard
export const GET_TRADING_DASHBOARD_TOP_PAIRS_URL = '/exchange/top-pairs';
export const GET_TRADING_DASHBOARD_BALANCES_URL = '/exchange/balances';
export const GET_TRADING_DASHBOARD_TOP_ASSETS_URL = '/exchange/trending-assets';
export const GET_TRADING_DASHBOARD_TOP_ORDERS_URL = '/exchange/top-orders';
export const GET_TRADING_DASHBOARD_TXN_GRAPH_URL = '/exchange/txn-graph';

import { combineReducers } from 'redux';
import auth from './auth/auth';
import dashboard from './dashboard/dashboard';
import user from './user/user';
import reward from './reward/reward';
import device from './device/device';
import password from './password/password';
import userReportManagement from './userReportManagement/userReportManagement';
import category from './category/category';
import profile from './profile/profile';
import modal from './modal/modal';
import transaction from './transaction/transaction';
import cms from './cms/cms';
import notification from './notification/notification';
import roleManagement from './roleManagement/roleManagement';
import adminManagement from './adminManagement/adminManagement';
import subscription from './subscription/subscription';
import dropManagement from './dropManagement/dropManagement';
import tradingDashboard from './trading-dashboard/tradingDashboard';

const allReducers = combineReducers({
  auth,
  modal,
  dashboard,
  user,
  reward,
  device,
  password,
  userReportManagement,
  category,
  profile,
  transaction,
  cms,
  notification,
  roleManagement,
  adminManagement,
  subscription,
  dropManagement,
  tradingDashboard,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default rootReducer;
